<template>
	<div class="detail-movie">
		<search-bar />

		<div class="detail-img">
			<div class="detail-img-left" :style="{ width: 'calc(100% ' + type == 'tv' ? '- 400px' : '- 0px)' }">
				<img :src="detail.poster" class="background" alt="" v-show="!isPlay" />
				<img :src="bigPlayImg" alt="" class="play" @click="goPlay" v-show="!isPlay" />
				<div id="dplayer" v-show="isPlay"></div>
			</div>
			<div class="detail-img-right" v-show="type == 'tv'">
				<div class="tv-box">
					<div class="tv-item-title">权利的游戏</div>
					<div class="tv-item-count">
						<div class="tv-item-count-left">
							总5季·共88集
						</div>
						<div class="tv-item-count-right">
							<span>自动播放</span>
							<el-switch v-model="isAuto" active-color="#FF3465" inactive-color="#ff4949"> </el-switch>
						</div>
					</div>
					<div class="tv-item" v-for="(item, index) in detail.tv" :key="index">
						<div class="tv-item-title">
							<div class="tv-item-title-left">
								<span class="tv-item-title-1">第二季</span>
								<span class="tv-item-title-2">全9集</span>
							</div>
							<div class="tv-item-title-right">
								<img :src="closeIcon" alt="" />
							</div>
						</div>
						<div class="tv-item-detail">
							<div
								class="tv-item-detail-item"
								v-for="(item, index) in [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
								:key="index"
							>
								{{ item }}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="movie-detail">
			<div class="moive-detail-left">
				<div class="moive-detail-content">
					<div class="moive-detail-left-left">
						<img :src="detail.thumb" alt="" class="moive-detiil-left-left-thmub" />
						<div class="moive-detail-left-left-button">
							<img :src="goodIcon" alt="" @click="clickGood()" />
							<img :src="nogoodIcon" alt="" @click="clickUnGood()" />
						</div>
					</div>
					<div class="moive-detail-left-right">
						<div class="moive-detail-left-right-title">
							<span>{{ detail.title }}</span>
							<span class="moive-detail-left-right-title-rating"> <span>9.5</span>/10 </span>
						</div>
						<div class="moive-detail-left-right-tags">
							<div v-for="(item, index) in detail.tags" :key="index">{{ item.tagName }}</div>
						</div>
						<div class="moive-detail-left-right-text">
							<label>导演</label>
							<div v-for="(item, index) in detail.actors" :key="index">
								<span v-show="index != 0">/</span>
								{{ item.name }}
							</div>
						</div>
						<div class="moive-detail-left-right-text">
							<label>主演</label>
							<div v-for="(item, index) in detail.directors" :key="index">
								<span v-show="index != 0">/</span>
								{{ item.name }}
							</div>
						</div>
						<div class="moive-detail-left-right-text">
							<label>简介</label>
							<div>
								{{ detail.intro }}
							</div>
						</div>
					</div>
				</div>

				<div class="moive-detail-comment">
					<div class="moive-detail-comment-avatar">
						<img :src="avatarImage" alt="" />
					</div>
					<div class="moive-detail-comment-text">
						<el-input style="width: 100%" type="textarea" :rows="2" placeholder="发表公开评论...">
						</el-input>
						<div class="moive-detail-comment-button">
							<div class="moive-detail-comment-button-text">0字</div>
							<div class="moive-detail-comment-button-button">发表</div>
						</div>
					</div>
				</div>
				<div class="moive-detail-comment-list">
					<div class="moive-detail-comment-list-title">
						<div class="moive-detail-comment-list-title-left">
							<img :src="titleIcon" alt="" />
							<span>评论（2）</span>
						</div>
						<div class="moive-detail-comment-list-title-right">
							<span @click="commentType = 1" :style="{ color: commentType == 1 ? '#ff3465' : '#797a8e' }"
								>按时间</span
							>
							<el-divider direction="vertical"></el-divider>
							<span @click="commentType = 2" :style="{ color: commentType == 2 ? '#ff3465' : '#797a8e' }"
								>按热度</span
							>
						</div>
					</div>
					<div class="no-work">
						<img :src="noworkIcon" alt="" />
						<span>评论维护中</span>
					</div>
				</div>
			</div>
			<div class="moive-detail-right"></div>
		</div>
	</div>
</template>

<script>
import searchBar from "./search-bar"
import DPlayer from "dplayer"
export default {
	name: "detail",
	data() {
		return {
			// type: "resultEmpty",
			type: "tv",
			// type: "normal",

			backgroundImage: require("@assets/index.png"),
			bigPlayImg: require("@assets/play_big.png"),
			avatarImage: require("@assets/avatar.png"),
			wantIcon: require("@assets/explore/want.png"),
			timeIcon: require("@assets/aside/aside7.png"),
			emptyIcon: require("@assets/explore/empty.png"),
			selectIcon: require("@assets/recommond/select.png"),
			selectIcon2: require("@assets/recommond/select2.png"),
			thumb: require("@assets/thumb.png"),

			goodIcon: require("@assets/detail/good.png"),
			nogoodIcon: require("@assets/detail/nogood.png"),
			buttonIcon1: require("@assets/detail/button1.png"),
			buttonIcon2: require("@assets/detail/button2.png"),
			buttonIcon3: require("@assets/detail/button3.png"),
			titleIcon: require("@assets/detail/title.png"),
			noworkIcon: require("@assets/detail/nowork.png"),

			openIcon: require("@assets/detail/open.png"),
			closeIcon: require("@assets/detail/close.png"),
			searchWidth: 0,
			searchPlaceHolder: "复仇者联盟",
			searchVal: "",
			user: {
				name: "胖虎不是铁憨憨",
			},

			activeName: "1",
			moiveList: [],

			isPlay: false,
			player: null,
			detail: {},

			currentPage: 1,
			pageSize: 20,
			commentType: "1",

			isAuto: true,
		}
	},
	methods: {
		async init() {
			const result = await this.api.getMoiveDetail({
				id: this.videoId,
			})

			console.log(result)
			this.detail = result.data
			const comment = await this.api.getComment({
				currentPage: this.currentPage,
				pageSize: this.pageSize,
				type: this.commentType,
				videoId: this.detail.videoId,
			})

			this.detail.tvSeasonCount
		},
		handleClick(tab, event) {
			console.log(tab, event)
		},
		goPlay() {
			this.isPlay = true
			const dp = new DPlayer({
				container: document.getElementById("dplayer"),
				autoplay: true,
				video: {
					defaultQuality: 0,
					quality: [
						{
							name: "高清",
							url: "https://biuvideo.dtslb.com/202009/09/enQ20JW4/index.m3u8",
							type: "hls",
						},
						{
							name: "720P",
							url: "https://biuvideo.dtslb.com/202009/09/enQ20JW4/800kb/hls/index.m3u8",
							type: "hls",
						},
						{
							name: "1080P",
							url: "https://biuvideo.dtslb.com/202009/09/enQ20JW4/index.m3u8",
							type: "hls",
						},
					],
				},
			})
		},

		getVideoUrl() {},
		async clickGood() {
			const result = await this.api.clickGood({
				videoId: this.detail.videoId,
			})
			if (result.code == 1) {
				this.$message.success("点赞成功")
			} else {
				this.$message.error(result.errormsg)
			}
		},
		async clickUnGood() {
			const result = await this.api.clickUnGood({
				videoId: this.detail.videoId,
			})
			if (result.code == 1) {
				this.$message.success("点踩成功")
			} else {
				this.$message.error(result.errormsg)
			}
		},
	},
	mounted() {
		this.videoId = 1
		this.init()
		this.searchWidth = (document.body.offsetWidth - 320) * 0.68 + "px"

		let hls = new Hls()
		hls.loadSource("https://biuvideo.dtslb.com/202009/09/enQ20JW4/index.m3u8")

		console.log(hls.levelController.levels)
		setTimeout(() => {})
		hls.on(Hls.Events.MANIFEST_PARSED, function(event, data) {
			console.log(hls.levels)
		})
	},
	components: {
		searchBar,
	},
}
</script>

<style lang="scss" scoped>
.detail-movie {
	background: rgb(19, 21, 48);
	width: calc(100% - 100px);
	height: 100%;
	overflow-y: auto;
	overflow-x: hidden;

	padding: 0 50px;
	.detail-img {
		margin-top: 30px;
		display: flex;
		justify-content: center;
		align-items: flex-start;
		.detail-img-right {
			width: 400px;
			flex-shrink: 0;
			height: 100%;
			background: #121330;
			.tv-box {
				padding: 20px 10px;
				.tv-item-title {
					font-size: 18px;
					font-family: PingFang SC;
					font-weight: 500;
					line-height: 25px;
					color: #ffffff;
				}
				.tv-item-count {
					display: flex;
					justify-content: space-between;
					align-items: center;
					margin-top: 10px;
					.tv-item-count-left {
						font-size: 12px;
						font-family: PingFang SC;
						font-weight: 400;
						line-height: 17px;
						color: #797a8e;
					}
					.tv-item-count-right {
						span {
							font-size: 12px;
							font-family: PingFang SC;
							font-weight: 400;
							line-height: 17px;
							color: #ffffff;
							margin-right: 10px;
						}
					}
				}
				.tv-item {
					background: rgba(255, 255, 255, 0.03);
					border-radius: 5px;
					margin-bottom: 10px;
					.tv-item-title {
						height: 60px;
						width: 100%;
						display: flex;
						justify-content: space-between;
						align-items: center;
						padding: 0 10px;
						.tv-item.title-left {
							.tv-item-title-1 {
								font-size: 16px;
								font-family: PingFang SC;
								font-weight: 500;
								line-height: 22px;
								color: #ff3465;
							}
							.tv-item-title-2 {
								font-size: 13px;
								font-family: PingFang SC;
								font-weight: 500;
								line-height: 18px;
								color: #797a8e;
								margin-left: 10px;
							}
						}
						.tv-item-title-right {
							width: 20px;
							height: 20px;
							cursor: pointer;
							img {
								width: 100%;
								height: 100%;
							}
						}
					}
					.tv-item-detail {
						display: flex;
						flex-wrap: wrap;
						justify-content: flex-start;
						align-items: flex-start;
						.tv-item-detail-item {
						}
					}
				}
			}
		}
		.detail-img-left {
			position: relative;
			width: 100%;
			.background {
				width: 100%;
			}
			.play {
				width: 270px;
				height: 270px;
				position: absolute;
				top: 0;
				display: block;
				right: 0;
				left: 0;
				bottom: 0;
				margin: auto;
				cursor: pointer;
			}
		}
	}
	.movie-detail {
		margin-top: 40px;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		.moive-detail-left {
			.moive-detail-content {
				display: flex;
				justify-content: center;
				align-items: center;
				.moive-detail-left-left {
					display: flex;
					justify-content: center;
					align-items: center;
					flex-direction: column;
					.moive-detiil-left-left-thmub {
						height: 230px;
						width: 160px;
					}
					.moive-detail-left-left-button {
						display: flex;
						justify-content: space-around;
						align-items: center;
						margin-top: 10px;
						width: 100%;
						img {
							width: 40px;
							height: 40px;
							cursor: pointer;
						}
					}
				}
				.moive-detail-left-right {
					margin-left: 20px;
					.moive-detail-left-right-title {
						font-size: 32px;
						font-family: PingFang SC;
						font-weight: 600;
						line-height: 45px;
						color: #b6b7c3;
						display: flex;
						justify-content: space-between;
						align-items: flex-start;
						.moive-detail-left-right-title-rating {
							font-size: 24px;
							font-family: DINPro;
							font-weight: 500;
							line-height: 31px;
							color: #797a8e;
							span {
								font-size: 40px;
								font-family: DINPro;
								font-weight: bold;
								line-height: 51px;
								color: #ff3465;
							}
						}
					}
					.moive-detail-left-right-tags {
						display: flex;
						justify-content: flex-start;
						align-items: center;
						margin-top: 12px;
						div {
							background: rgba(255, 52, 101, 0.05);
							padding: 3px 10px;

							font-size: 13px;
							font-family: PingFang SC;
							font-weight: 500;
							line-height: 18px;
							color: #ff3465;
							margin-right: 10px;
							border-radius: 5px;
						}
					}
					.moive-detail-left-right-text {
						display: flex;
						justify-content: flex-start;
						align-items: flex-start;
						margin-top: 20px;

						label {
							font-size: 15px;
							font-family: PingFang SC;
							font-weight: 400;
							line-height: 21px;
							color: #797a8e;
							margin-right: 12px;
							flex-shrink: 0;
						}
						div {
							font-size: 15px;
							font-family: PingFang SC;
							font-weight: 500;
							line-height: 21px;
							color: #797a8e;
						}
					}
				}
			}

			.moive-detail-comment {
				display: flex;
				justify-content: flex-start;
				align-items: flex-start;
				margin-top: 60px;
				.moive-detail-comment-avatar {
					width: 60px;
					height: 60px;
					overflow: hidden;
					border-radius: 50%;
					img {
						width: 100%;
						height: 100%;
					}
				}
				.moive-detail-comment-text {
					width: calc(100% - 85px);
					margin-left: 25px;
					.moive-detail-comment-button {
						display: flex;
						justify-content: space-between;
						align-items: center;
						margin-top: 10px;
						.moive-detail-comment-button-text {
							font-size: 16px;
							font-family: PingFang SC;
							font-weight: 400;
							line-height: 22px;
							color: #797a8e;
						}
						.moive-detail-comment-button-button {
							width: 100px;
							height: 45px;
							background: #ff3465;
							opacity: 1;
							border-radius: 10px;

							font-size: 16px;
							font-family: PingFang SC;
							font-weight: 400;
							line-height: 22px;
							color: #ffffff;
							display: flex;
							justify-content: center;
							align-items: center;
						}
					}
				}
			}
			.moive-detail-comment-list {
				margin-top: 30px;

				width: 100%;
				.moive-detail-comment-list-title {
					display: flex;
					justify-content: space-between;
					align-items: center;
					width: 100%;
					img {
						width: 20px;
						height: 20px;
					}
					span {
						font-size: 18px;
						font-family: PingFang SC;
						font-weight: 600;
						line-height: 25px;
						color: #797a8e;
						margin-left: 8px;
						cursor: pointer;
					}
				}
				.no-work {
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					margin-bottom: 200px;
					img {
						width: 300px;
						height: 210px;
					}
					span {
						font-size: 15px;
						font-family: PingFang SC;
						font-weight: 500;
						line-height: 21px;
						color: #797a8e;
						margin-top: 20px;
					}
				}
			}
		}
		.moive-detail-right {
		}
	}
}

.moive-detail-comment {
	.el-textarea {
	}
	.el-input__inner,
	.el-textarea__inner {
		background-color: rgba(121, 122, 142, 0.1);
		color: #b6b7c3;
		border: none !important;
	}
	.el-form-item__label {
		font-size: 15px;
		font-family: PingFang SC;
		font-weight: 500;
		color: rgba(121, 122, 142, 1);
	}
}
</style>
